import React, { FC, ReactElement } from 'react';

const NotFound:FC = ():ReactElement => {
  return (
    <div className='unfinished-page'>
      NotFound
    </div>
  );
}

export default NotFound;
