import React, { FC, MouseEventHandler, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import './index.css'

const TopBar:FC = ():ReactElement => {

  const history = useHistory()
  const turnPage = (page:string)=> {
    history.push(page)
  }

  const tbLeft:MouseEventHandler = ():void => {
    if(window.location.href.slice(-4) !== 'home') {
      turnPage('/home')
    }
    console.log('tbLeft');
  }

  const tbRight:MouseEventHandler = ():void => {
    console.log('tbRight');
  }

  return (
    <div id="topbar">
      <div id="topbar-left" onClick={tbLeft}>
        {/* <svg viewBox="0 0 1024 1024"><path d="M904.715163 540.069146H399.795668c-15.497636 0-28.033021-12.535384-28.033021-28.033021 0-15.461511 12.535384-28.105271 28.033021-28.105271h504.95562c15.497636 0 28.105271 12.643759 28.10527 28.105271 0 15.497636-12.643759 28.033021-28.141395 28.033021z m0-224.44479H399.795668c-15.497636 0-28.033021-12.535384-28.033021-28.03302a28.033021 28.033021 0 0 1 28.033021-28.033021h504.95562c15.497636 0 28.105271 12.535384 28.10527 28.033021 0 15.497636-12.643759 28.033021-28.141395 28.03302zM203.456149 792.546955c-30.959148 0-56.138291-25.070768-56.138291-56.138291 0-30.959148 25.179143-56.029916 56.138291-56.029916s56.138291 25.070768 56.138291 56.029916c0 31.067523-25.179143 56.138291-56.138291 56.138291z m0-224.444789c-30.959148 0-56.138291-25.070768-56.138291-56.029916 0-31.031398 25.179143-56.138291 56.138291-56.138291s56.138291 25.070768 56.138291 56.138291c0 30.923023-25.179143 56.029916-56.138291 56.029916z m0-224.372539c-30.959148 0-56.138291-25.179143-56.138291-56.138291s25.179143-56.138291 56.138291-56.138291 56.138291 25.179143 56.138291 56.138291-25.179143 56.138291-56.138291 56.138291z m196.339519 364.682142h504.95562c15.497636 0 28.105271 12.535384 28.10527 28.03302 0 15.569886-12.643759 28.105271-28.10527 28.105271H399.795668c-15.497636 0-28.033021-12.535384-28.033021-28.105271 0-15.497636 12.535384-28.033021 28.033021-28.03302z" fill="#ffffff" p-id="11017"></path></svg> */}
        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2376" width="100" height="100"><path d="M96 480c-9.6 0-19.2-3.2-25.6-12.8-12.8-12.8-9.6-35.2 3.2-44.8l377.6-310.4c35.2-25.6 86.4-25.6 118.4 0l377.6 307.2c12.8 9.6 16 32 3.2 44.8-12.8 12.8-32 16-44.8 3.2L531.2 166.4c-9.6-6.4-28.8-6.4-38.4 0L115.2 473.6c-6.4 6.4-12.8 6.4-19.2 6.4zM816 928H608c-19.2 0-32-12.8-32-32v-150.4c0-22.4-38.4-44.8-67.2-44.8-28.8 0-64 19.2-64 44.8V896c0 19.2-12.8 32-32 32H211.2C163.2 928 128 892.8 128 848V544c0-19.2 12.8-32 32-32s32 12.8 32 32v304c0 9.6 6.4 16 19.2 16H384v-118.4c0-64 67.2-108.8 128-108.8s131.2 44.8 131.2 108.8V864h176c9.6 0 16 0 16-19.2V544c0-19.2 12.8-32 32-32s32 12.8 32 32v304C896 896 864 928 816 928z" fill="#ffffff" p-id="2377"></path></svg>
      </div>
      {/* <div id="topbar-middle">logo</div> */}
      {/* <div id="topbar-right" onClick={tbRight}>
        <svg viewBox="0 0 1024 1024"><path d="M416.4 958h191.2V849.7c0-12.7 6.4-25.5 19.1-31.9 31.9-12.7 63.7-31.9 89.2-51 12.7-6.4 25.5-6.4 38.2 0l95.6 57.3 95.6-165.7-95.6-57.3C837 588.5 830.6 575.7 837 563c0-19.1 6.4-31.9 6.4-51s0-31.9-6.4-51c0-12.7 6.4-25.5 12.7-31.9l95.6-57.3-95.6-165.7-95.6 57.3c-12.7 6.4-25.5 6.4-38.2 0-25.5-19.1-57.3-38.2-89.2-51-12.7-12.7-19.1-25.5-19.1-38.2V66H416.4v108.3c0 12.7-6.4 25.5-19.1 31.9-31.9 12.7-63.7 31.9-89.2 51-12.7 6.4-25.5 6.4-38.2 0l-95.6-51-95.6 165.6 95.6 57.3c12.7 6.4 19.1 19.1 12.7 31.9 0 19.1-6.4 31.9-6.4 51s0 31.9 6.4 51c6.4 12.7 0 25.5-12.7 31.9l-95.6 57.3 95.6 165.7 95.6-57.3c12.7-6.4 25.5-6.4 38.2 0 25.5 19.1 57.3 38.2 89.2 51 12.7 6.4 19.1 19.1 19.1 31.9V958z m223 63.7H384.6c-19.1 0-31.9-12.7-31.9-31.9v-121c-25.5-12.7-51-25.5-70.1-38.2l-101.9 63.7c-12.7 6.4-31.9 6.4-44.6-12.7L8.6 658.6c-12.7-19.1-6.4-38.2 12.7-44.6l101.9-63.7v-76.5L21.4 410.1c-19.1-6.4-25.5-25.5-12.7-44.6l127.4-223c6.4-12.7 25.5-19.1 44.6-6.4l101.9 63.7c19.1-12.7 44.6-31.9 70.1-38.2V34.1c0-19.1 12.7-31.9 31.9-31.9h254.9c19.1 0 31.9 12.7 31.9 31.9v121.1c25.5 12.7 51 25.5 70.1 38.2l101.9-63.7c12.7-6.4 31.9-6.4 44.6 12.7l127.4 223c12.7 19.1 6.4 38.2-12.7 44.6l-101.9 63.7v76.5l101.9 63.7c12.7 6.4 19.1 25.5 12.7 44.6L888 881.5c-6.4 12.7-25.5 19.1-44.6 12.7l-101.9-63.7c-19.1 12.7-44.6 31.9-70.1 38.2v121.1c-0.1 19.2-12.8 31.9-32 31.9zM512 703.2c-108.3 0-191.2-82.8-191.2-191.2S403.7 320.8 512 320.8 703.2 403.7 703.2 512 620.3 703.2 512 703.2z m0-318.6c-70.1 0-127.4 57.3-127.4 127.4S441.9 639.4 512 639.4 639.4 582.1 639.4 512 582.1 384.6 512 384.6z" p-id="9416" fill="#ffffff"></path></svg>
      </div> */}
    </div>
  );
}

export default TopBar;
